import type {
  S3ClientConfig,
  PutObjectCommandInput,
  DeleteObjectCommandInput,
  GetObjectCommandInput,
  ListObjectsV2CommandInput,
  ListObjectsV2Output,
  DeleteObjectsCommandOutput,
  DeleteObjectCommandOutput,
} from '@aws-sdk/client-s3';
import type { Progress as ProgressAWS } from '@aws-sdk/lib-storage';
import type { Hash } from '@super-protocol/dto-js';
import { joinPathsFile, joinPathsFolder } from 'utils';

export interface UploadFileByS3Props {
    stream: ReadableStream;
    path: string;
}

export interface UploadFileStreamS3 {
  clientConfig: S3ClientConfig;
  params: Omit<PutObjectCommandInput, 'Key' | 'Body'>;
  prefix: string;
}

export interface DeleteFileS3 {
  clientConfig: S3ClientConfig;
  params: Omit<DeleteObjectCommandInput, 'Key'>;
  prefix: string;
}

export interface DeleteFileProps {
  s3: DeleteFileS3;
  path: string;
}

export interface DeleteFolderS3 {
  clientConfig: S3ClientConfig;
  params: Omit<ListObjectsV2CommandInput, 'Key'>;
  prefix: string;
}

export interface DeleteFolderProps {
  s3: DeleteFolderS3;
  path: string;
}

export interface UploadFileS3 {
  clientConfig: S3ClientConfig;
  params: Omit<PutObjectCommandInput, 'Key'>;
  prefix: string;
}

export interface UploadFileProps {
  s3: UploadFileS3;
  path: string;
  abortController?: AbortController;
}

export interface Progress extends ProgressAWS {}

export interface UploadFileStreamProps {
    stream: ReadableStream;
    s3: UploadFileStreamS3;
    path: string;
    onProgress?: (progress: Progress) => void;
    abortController?: AbortController;
}

export interface DownloadFileStreamS3 {
  clientConfig: S3ClientConfig;
  params: Omit<GetObjectCommandInput, 'Key' | 'Body'>;
  prefix: string;
}

export interface DownloadFileStreamProps {
  abortController?: AbortController;
  s3: DownloadFileStreamS3;
  path: string;
}

export interface UploadFileResult {
  hash: Hash;
  mac: Buffer;
}

export interface DownloadFileS3 {
  clientConfig: S3ClientConfig;
  params: Omit<GetObjectCommandInput, 'Key'>;
  prefix: string;
}

export interface DownloadFileProps {
  abortController?: AbortController;
  s3: DownloadFileS3;
  path?: string;
}

export interface GetListS3 {
  clientConfig: S3ClientConfig;
  params: Omit<ListObjectsV2CommandInput, 'Key'>;
  prefix: string;
}

export interface GetListProps {
  abortController?: AbortController;
  s3: GetListS3;
  path: string;
}

export interface CreateFolderProps {
  path: string;
  s3: UploadFileS3;
}

export const uploadFileStream = async (props: UploadFileStreamProps) => {
  const {
    stream, s3, onProgress, abortController, path,
  } = props;

  const { clientConfig, params, prefix } = s3;
  const { S3Client } = await import('@aws-sdk/client-s3');
  const { Upload } = await import('@aws-sdk/lib-storage');

  const upload = new Upload({
    client: new S3Client(clientConfig),
    params: {
      Key: joinPathsFile(prefix, path),
      Body: stream,
      ...params,
    },
    abortController,
  });

  upload.on('httpUploadProgress', (progress: Progress) => {
    onProgress?.(progress);
  });

  await upload.done();
};

export const downloadFileStream = async (props: DownloadFileStreamProps): Promise<ReadableStream | undefined> => {
  const {
    path, s3, abortController,
  } = props;
  const {
    clientConfig, params, prefix,
  } = s3;
  const { S3Client, GetObjectCommand } = await import('@aws-sdk/client-s3');

  const s3Client = new S3Client(clientConfig);

  const command = new GetObjectCommand({
    Key: joinPathsFile(prefix, path),
    ...params,
  });
  const response = await s3Client.send(command, {
    abortSignal: abortController?.signal,
  });
  return response.Body as ReadableStream;
};

export const deleteFile = async (props: DeleteFileProps): Promise<DeleteObjectCommandOutput> => {
  const { s3, path } = props;
  const { clientConfig, params, prefix } = s3;
  const { S3Client, DeleteObjectCommand } = await import('@aws-sdk/client-s3');
  const client = new S3Client(clientConfig);
  const deleteObjectCommand = new DeleteObjectCommand({ ...params, Key: joinPathsFile(prefix, path) });
  return client.send(deleteObjectCommand);
};

export const deleteFolder = async (props: DeleteFolderProps): Promise<DeleteObjectsCommandOutput | null> => {
  const { s3, path } = props;
  const { clientConfig, params } = s3;
  const { S3Client, ListObjectsV2Command, DeleteObjectsCommand } = await import('@aws-sdk/client-s3');

  const client = new S3Client(clientConfig);

  const listCommand = new ListObjectsV2Command({
    ...params,
    Prefix: joinPathsFolder(params.Prefix || '', path),
  });

  const listedObjects = await client.send(listCommand);

  if (!listedObjects.Contents?.length) {
    return null;
  }

  const deleteParams = {
    ...params,
    Delete: {
      Objects: listedObjects.Contents.map((obj) => ({ Key: obj.Key! })),
    },
  };

  const deleteCommand = new DeleteObjectsCommand(deleteParams);
  return client.send(deleteCommand);
};

export const uploadFile = async (props: UploadFileProps) => {
  const {
    s3, abortController, path,
  } = props;
  const { clientConfig, params, prefix } = s3;
  const { S3Client } = await import('@aws-sdk/client-s3');
  const { Upload } = await import('@aws-sdk/lib-storage');
  const upload = new Upload({
    client: new S3Client(clientConfig),
    params: {
      Key: joinPathsFile(prefix, path),
      ...params,
    },
    abortController,
  });
  await upload.done();
};

export const downloadFile = async (props: DownloadFileProps): Promise<ReadableStream | undefined> => {
  const {
    s3, abortController, path,
  } = props;
  const { clientConfig, params, prefix } = s3;
  const { S3Client, GetObjectCommand } = await import('@aws-sdk/client-s3');
  const s3Client = new S3Client(clientConfig);

  const command = new GetObjectCommand({
    Key: joinPathsFile(prefix, path || ''),
    ...params,
  });
  const response = await s3Client.send(command, {
    abortSignal: abortController?.signal,
  });
  const { Body } = response;
  if (!Body) return undefined;
  return Body as ReadableStream;
};

export const getList = async (props: GetListProps): Promise<ListObjectsV2Output> => {
  const {
    s3, abortController, path,
  } = props;
  const { clientConfig, params, prefix } = s3;
  const { S3Client, ListObjectsV2Command } = await import('@aws-sdk/client-s3');
  const s3Client = new S3Client(clientConfig);

  const Prefix = joinPathsFolder(prefix, path);

  const command = new ListObjectsV2Command({
    ...params,
    Prefix,
  });
  return s3Client.send(command, {
    abortSignal: abortController?.signal,
  });
};

export const createFolder = async (props: CreateFolderProps): Promise<void> => {
  const { path, s3 } = props;
  const { clientConfig, params, prefix } = s3;
  const { S3Client, PutObjectCommand } = await import('@aws-sdk/client-s3');

  const s3Client = new S3Client(clientConfig);
  const folderKey = joinPathsFolder(prefix, path);

  const command = new PutObjectCommand({
    Key: folderKey,
    Body: '',
    ...params,
  });

  await s3Client.send(command);
};